/* eslint-disable max-lines */
import { Capacitor } from "@capacitor/core";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { type CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { type CbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { differenceInMilliseconds } from "date-fns";
import HyperTrack, { type OrderStatus } from "hypertrack-sdk-ionic-capacitor";

import { getDeviceGeoLocation } from "../deviceLocation";
import { NonNativePlatformGeolocationError } from "../deviceLocation/NonNativePlatformGeolocationError";
import type { GeoLocation } from "../types";
import { calculateGeoDistanceInMiles } from "./geoDistance";

interface GetGeofenceStatusParams {
  requestUniqueId: string;
  action: "clock-in" | "clock-out";
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
  isHyperTrackEnabled: boolean;
  fallbackGeofenceDistanceInMiles?: number;
  fallbackGeofenceLocation?: GeoLocation;
}

export interface GetGeofenceStatusResult {
  isInsideGeofence: boolean;
  checkProviders: Array<"hypertrack" | "device-gps">;
  error?: unknown;
}

export async function getGeofenceStatus(
  params: GetGeofenceStatusParams
): Promise<GetGeofenceStatusResult> {
  const {
    requestUniqueId,
    action,
    hyperTrackGeotagEvents,
    isHyperTrackEnabled,
    fallbackGeofenceDistanceInMiles,
    fallbackGeofenceLocation,
  } = params;

  const { isInsideGeofence, error } = isHyperTrackEnabled
    ? await checkIsWorkerInsideFacilityGeofenceHyperTrack({
        orderHandle: requestUniqueId,
        action,
        hyperTrackGeotagEvents,
      })
    : {
        isInsideGeofence: false,
        error: "No geofence check enabled",
      };

  const checkProviders = (isHyperTrackEnabled ? ["hypertrack"] : []) as Array<
    "hypertrack" | "device-gps"
  >;

  if (!isHyperTrackEnabled || isDefined(error)) {
    if (!isDefined(fallbackGeofenceDistanceInMiles) || !isDefined(fallbackGeofenceLocation)) {
      return {
        error,
        isInsideGeofence: false,
        checkProviders,
      };
    }

    checkProviders.push("device-gps");
    const result = await checkIsWorkerInsideFacilityGeofenceFallback({
      orderHandle: requestUniqueId,
      fallbackGeofenceLocation,
      fallbackGeofenceDistanceInMiles,
    });

    return {
      isInsideGeofence: result.isInsideGeofence,
      error: result.error,
      checkProviders,
    };
  }

  return {
    isInsideGeofence,
    checkProviders,
    error,
  };
}

async function checkIsWorkerInsideFacilityGeofenceFallback({
  orderHandle,
  fallbackGeofenceLocation,
  fallbackGeofenceDistanceInMiles,
}: {
  orderHandle: string;
  fallbackGeofenceLocation: GeoLocation;
  fallbackGeofenceDistanceInMiles: number;
}): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  const startDate = new Date();

  try {
    logEvent(APP_V2_APP_EVENTS.FALLBACK_GEOFENCE_CHECK, {
      metadata: {
        orderHandle,
      },
    });

    const { geoLocation } = await getDeviceGeoLocation();
    const distanceInMiles = calculateGeoDistanceInMiles(geoLocation, fallbackGeofenceLocation);

    const isInsideGeofence = distanceInMiles <= fallbackGeofenceDistanceInMiles;

    logEvent(APP_V2_APP_EVENTS.FALLBACK_GEOFENCE_CHECK_SUCCEEDED, {
      metadata: {
        orderHandle,
        distanceInMiles,
        isInsideGeofence,
        durationInMs: differenceInMilliseconds(new Date(), startDate),
      },
    });

    return {
      isInsideGeofence,
    };
  } catch (error) {
    logError(
      APP_V2_APP_EVENTS.FALLBACK_GEOFENCE_CHECK_FAILED,
      {
        error,
        metadata: {
          orderHandle,
          durationInMs: differenceInMilliseconds(new Date(), startDate),
        },
      },
      true
    );

    return {
      isInsideGeofence: false,
      error,
    };
  }
}

async function checkIsWorkerInsideFacilityGeofenceHyperTrack({
  orderHandle,
  action,
  hyperTrackGeotagEvents,
}: {
  orderHandle: string;
  action: "clock-in" | "clock-out";
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
}): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  const startDate = new Date();

  const { events } = hyperTrackGeotagEvents;
  const shouldGeotagFailures = events === "failures" || events === "all";
  const shouldGeotagSuccesses = events === "all";

  if (!Capacitor.isNativePlatform()) {
    const nonNativePlatformError = new NonNativePlatformGeolocationError();

    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: nonNativePlatformError,
        metadata: {
          orderHandle,
          durationInMs: differenceInMilliseconds(new Date(), startDate),
        },
      },
      true
    );

    return { isInsideGeofence: false, error: nonNativePlatformError };
  }

  const activeOrders = await HyperTrack.getOrders();
  const currentOrder = activeOrders.get(orderHandle);
  let orderStatus: OrderStatus = { type: "orderStatusClockIn" };
  if (action === "clock-out") {
    orderStatus = { type: "orderStatusClockOut" };
  }

  if (isNullOrUndefined(currentOrder)) {
    const activeOrderHandles = [...activeOrders.keys()];

    const failedToFetchCurrentOrderError = new Error(`Current order not found`);
    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: "Current order not found",
        metadata: {
          orderHandle,
          activeOrderHandles,
          numActiveOrders: activeOrderHandles.length,
          durationInMs: differenceInMilliseconds(new Date(), startDate),
        },
      },
      true
    );

    if (shouldGeotagFailures) {
      await HyperTrack.addGeotag(orderHandle, orderStatus, {
        activeOrders,
        numActiveOrders: activeOrderHandles.length,
        reason: failedToFetchCurrentOrderError.message,
        isInsideGeofence: false,
        attemptedAt: startDate.toISOString(),
        durationInMs: differenceInMilliseconds(new Date(), startDate),
        stage: action,
      });
    }

    return { isInsideGeofence: false, error: failedToFetchCurrentOrderError };
  }

  const isInsideGeofenceResult = await currentOrder.isInsideGeofence();
  switch (isInsideGeofenceResult.type) {
    case "success": {
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED, {
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
          isInsideGeofence: isInsideGeofenceResult.value,
          durationInMs: differenceInMilliseconds(new Date(), startDate),
        },
      });

      if (isInsideGeofenceResult.value) {
        if (shouldGeotagSuccesses) {
          await HyperTrack.addGeotag(orderHandle, orderStatus, {
            isInsideGeofence: true,
            attemptedAt: startDate.toISOString(),
            stage: action,
          });
        }
      } else if (shouldGeotagFailures) {
        await HyperTrack.addGeotag(orderHandle, orderStatus, {
          reason: "Too far away",
          isInsideGeofence: false,
          attemptedAt: startDate.toISOString(),
          stage: action,
        });
      }

      return { isInsideGeofence: isInsideGeofenceResult.value };
    }

    case "failure": {
      const failedToFetchGeofenceStatusError = new Error(
        `Failed to fetch geofence status for current order`
      );
      logError(
        APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
        {
          error: failedToFetchGeofenceStatusError,
          metadata: {
            orderHandle,
            activeOrders,
            currentOrder,
            durationInMs: differenceInMilliseconds(new Date(), startDate),
          },
        },
        true
      );

      if (shouldGeotagFailures) {
        await HyperTrack.addGeotag(orderHandle, orderStatus, {
          activeOrders,
          reason: failedToFetchGeofenceStatusError.message,
          isInsideGeofence: false,
          attemptedAt: startDate.toISOString(),
          stage: action,
        });
      }

      return { isInsideGeofence: false, error: failedToFetchGeofenceStatusError };
    }

    default: {
      logError(
        APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
        {
          error: "Unhandled hypertrack geofence status",
          metadata: {
            orderHandle,
            activeOrders,
            currentOrder,
            durationInMs: differenceInMilliseconds(new Date(), startDate),
          },
        },
        true
      );
      return { isInsideGeofence: false, error: new Error("Unhandled hypertrack geofence status") };
    }
  }
}

/* eslint-enable max-lines */
